export function routes($stateProvider) {

  $stateProvider
    .state('app.aplicacoes', {
      url: '/app',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.aplicacoes.directory', {
      template: require('./directory/app.view.html'),
      url: '/',
      controller: 'AppController',
      controllerAs: 'vm',
    })
    .state('app.aplicacoes.details', {
      template: require('./details/details.view.html'),
      url: '/{id}',
      params: {
        major: undefined,
        minor: undefined,
        patch: undefined,
        expanded: false,
      },
      controller: 'AppControllerDetails',
      controllerAs: 'vm',
      role: '$authenticated'
    })
    .state('app.aplicacoes.edit', {
      template: require('./details/changedetails/changeDetails.html'),
      url: '/:appId/editor/:major/:minor/:patch',
      controller: 'ChangelogAppController',
      controllerAs: 'vm',
      role: 'changelog'
    })
  .state('app.aplicacoes.add', {
    template: require('./details/changedetails/addChangelog.html'),
    url: '/:appId/add',
    controller: 'ChangelogAppController',
    controllerAs: 'vm',
    role: 'changelog'
  });
}

routes.$inject = ['$stateProvider'];
