export default class PackageController {
  constructor(UI, $state, App, Changelogapp) {
    this.UI = UI;
    this.$state = $state;
    this.package = $state.params.name;
    this.App = App;
    this.Changelogapp = Changelogapp;
    this.loaded = false;

    if ($state.params.env.toUpperCase() === 'PROD') {
      this.env = {
        filter: {
          production: 1,
        },
        string: 'Produção'
      };
    }
    if ($state.params.env.toUpperCase() === 'STAGING') {
      this.env = {
        filter: {
          staging: 1
        },
        string: 'Staging'
      };
    }
    if ($state.params.env.toUpperCase() === 'DEV') {
      this.env = {
        filter: {
          dev: 1
        },
        string: 'Desenvolvimento'
      };
    }
    this.init();
    this.limit = 3;
  }

  getUrl = () => {

  };

  limitTo = () => {
    if (this.limit) {
      this.limit = null;
    } else {
      this.limit = 3;
    }
  };

  init = () => {
    this.App.findOne({
      filter: {
        where: {
          active: true,
          package: {
            like: this.package,
          }
        }
      }
    }).$promise.then(app => {
      let where = this.env.filter;
      where.active = true;
      where.appId = app.id;
      this.Changelogapp.find({
        filter: {
          where: where,
          order: 'partsum desc'
        }
      }).$promise.then(versions => {
        app.versions = versions;
        if (this.$state.params.version === null) {
          this.setVersion(versions[0]);
        } else {
          versions.forEach(v => {
            v.string = `${v.major}.${v.minor}.${v.patch}`;
            if (v.string === this.$state.params.version) {
              this.setVersion(v);
            }
          });
          if (angular.isUndefined(this.version)) {
            this.setVersion(versions[0]);
          }
        }
        this.data = app;
        this.loaded = true;
      });
    });
  };

  setVersion = (version) => {
    let params = {
      name: this.$state.params.name,
      env: this.$state.params.env,
      version: `${version.major}.${version.minor}.${version.patch}`
    };
    this.version = version;
    this.$state.go("public.package", params, {
      notify:false,
      reload:false,
      location:'replace',
      inherit:true
    });
  }
}

PackageController.$inject = ['UIService', '$state', 'App', 'Changelogapp'];
