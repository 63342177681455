export default class ChangelogController {
  constructor($sce, $stateParams, UIService, ChangelogService, Authorization, App, Changelogapp) {
    this.$stateParams = $stateParams;
    this.Changelog = ChangelogService;
    this.UI = UIService;
    this.filter = "";
    this.loaded = false;
    this.versionLoading = true;
    this.showAll = $stateParams.expanded;
    this.Authorization = Authorization;
    this.limit = this.showAll ? undefined: 3;
    this.App = App;
    this.Changelogapp = Changelogapp;
    this.load();
  }

  load = () => {
    this.Changelog.getVersion(this.$stateParams.major, this.$stateParams.minor, this.$stateParams.patch).then((version) => {
      this.version = version;
      this.Changelog.getVersions().then(r => this.versions = r);
      this.loaded = true;
      this.versionLoading = false;
    }).catch(err => {
      this.UI.addToast('Não foi possível carregar o changelog. Por favor, tente mais tarde!');
    });
  };

  isSelected = (v) => {
    return (v.major == this.version.major && v.minor == this.version.minor && v.patch == this.version.patch);
  };

  limitTo = () => {
    if (this.filter.length === 0) {
      if (!this.showAll) {
        this.limit = undefined;
        this.showAll = true;
      } else {
        this.limit = 3;
        this.showAll = false;
      }
    }
  };

  isAllowed = () => {
    return this.Authorization.canPerform('changelog');
  };

  filtering = () => {
    if (this.filter.length !== 0) {
      this.showAll = true;
      this.limit = undefined;
    } else {
      this.limitTo();
    }
  }


};


ChangelogController.$inject = ['$sce', '$stateParams', 'UIService', 'ChangelogService', 'AuthorizationService', 'App', 'Changelogapp'];
