export default class ClientDetailsController {
  constructor($state, Client, Contact, Contract, Attempt, Support, UIService) {
    this.$state = $state;
    this.Client = Client;
    this.Contact = Contact;
    this.Support = Support;
    this.Contract = Contract;
    this.Attempt = Attempt;
    this.UI = UIService;
    this.id = $state.params.id;
    this.loaded = false;
    this.loadData();
  }

  syncHelpdesk = id => {
    let wait = this.UI.showWaiting();
    this.Support.syncByContract({id: id}).$promise.then(r => {
      this.UI.addToast("Tickets atualizados!");
      wait.close();
    }).catch(e => {
      wait.close();
      this.UI.addToast(e);
    })
  }

  showAttempt = (attempt) => {
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./attempt.dialog.html'),
      controller: ['$scope', $scope => {
        if (angular.isUndefined(attempt)) {
          $scope.attempt = {
            description: "",
            name: "",
            clientId: this.id,
            active: true,
          };
        } else {
          $scope.attempt = attempt;
        }

        $scope.ok = () => {
          $scope.$close($scope.attempt);
        };

        $scope.cancel = () => {
          $scope.$dismiss();
        };
      }]
    });

    dialog.then(res => {
      if (res) {
        this.Attempt.upsert(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast(`Oportunidade de negócio ${res.id ? 'alterada' : 'adicionada'}!`);
        }).catch(() => {
          this.UI.addToast('Erro ao guardar oportunidade de negócio');
        })
      }
    });
  };

  showClient = () => {
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./client.dialog.html'),
      controller: ['$scope', 'FileUploader', ($scope, FileUploader) => {

        $scope.uploader = new FileUploader({
          url: '/api/assets/upload/logos',
          queueLimit: 1
        });
        // Force items to be only image
        let filter = {
          name: 'verifyIMG',
          fn: function (item, options) {
            return item.type.indexOf("image/") !== -1;
          }
        };
        $scope.uploader.filters.push(filter);

        $scope.uploader.onSuccessItem = (item, response) => {
          $scope.client.logo = {
            container: response.result.container,
            year: response.result.year,
            month: response.result.month,
            name: response.result.name,
            size: response.result.size,
          };
          $scope.$close($scope.client);
        };


        $scope.pickImage = () => {
          angular.element('#fileinput').trigger('click');
        };

        $scope.client = angular.copy(this.client);

        $scope.generateCode = function () {
          if ($scope.client.hasOwnProperty('name')) {
            let matches = $scope.client.name.match(/\b(\w)/g); // ['J','S','O','N']
            $scope.client.code = matches.join(''); // JSON
          }
        };

        $scope.ok = () => {
          // If upload queue is not empty, upload image
          if ($scope.uploader.queue.length > 0) {
            $scope.uploader.uploadAll();
          } else {
            $scope.$close($scope.client);
          }
        };

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    });

    dialog.then(res => {
      if (res) {
        this.Client.upsert(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast("Cliente alterado!");
        }).catch(() => {
          this.UI.addToast("Ocorreu um erro ao alterar cliente");
        })
      }
    });
  };

  showContract = (contract) => {
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./contract.dialog.html'),
      controller: ['$scope', $scope => {
        if (angular.isUndefined(contract)) {
          $scope.contract = {
            clientId: this.id,
            active: true,
          };
        } else {
          $scope.contract = contract;
        }

        $scope.ok = () => {
          $scope.$close($scope.contract);
        };

        $scope.cancel = () => {
          $scope.$dismiss();
        };
      }]
    });

    dialog.then(res => {
      if (res) {
        this.Contract.upsert(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast(`Contracto ${res.id ? 'alterado' : 'adicionado'}!`);
        }).catch(() => {
          this.UI.addToast('Erro ao guardar contracto');
        })
      }
    });
  };

  showContact = (contact) => {
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./contact.dialog.html'),
      controller: ['$scope', $scope => {
        if (angular.isUndefined(contact)) {
          $scope.contact = {
            clientId: this.id,
            active: true,
            data: []
          };
        } else {
          $scope.contact = contact;
        }

        $scope.ok = () => {
          $scope.$close($scope.contact);
        };

        $scope.cancel = () => {
          $scope.$dismiss();
        };

        $scope.add = () => {
          let wait = this.UI.showDialog({
            size: 'md',
            template: require('./info.dialog.html'),
            controller: ['$scope', $scope => {
              $scope.data = {};

              $scope.ok = () => {
                $scope.$close($scope.data);
              };

              $scope.cancel = () => {
                $scope.$dismiss();
              };
            }]
          });
          wait.then(res => {
            // Yap, just this xD
            if (res) {
              $scope.contact.data.push(res);
            }
          })
        }
      }]
    });

    dialog.then(res => {
      if (res) {
        this.Contact.upsert(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast(`Contacto ${res.id ? 'alterado' : 'adicionado'}!`);
        }).catch(() => {
          this.UI.addToast('Erro ao guardar contacto');
        })
      }
    });
  };

  sumHoursOf(contract) {
    let sum = contract.supports.reduce((a, {elapsed}) => a + (elapsed || 0), 0);
    return moment.duration(sum, 'm').asHours().toFixed(1);
  };

  loadData = () => {
    this.loaded = false;
    this.Client.findOne({
      filter: {
        where: {
          id: this.$state.params.id
        },
        include: ['attempts', 'contacts', {
          relation: 'contracts',
          scope: {
            include: 'supports'
          }
        }]
      }
    }).$promise.then(res => {
      this.client = res;
      this.loaded = true;
    }).catch(() => {
      this.UI.addToast('Não foi possível encontrar o cliente especificado');
      this.$state.go('app.clients.list');
    })
  };
}

ClientDetailsController.$inject = ['$state', 'Client', 'Contact', 'Contract', 'Attempt', 'Support', 'UIService'];

