export default class SupportDetailsController {
  constructor($state, Support, UIService, AuthenticationService) {
    this.id = $state.params.id;
    this.$state = $state;
    this.Support = Support;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.Support.findOne({
      filter: {
        where: {
          id: this.id
        },
        include: [{
          relation: 'contact',
          scope: {
            include: 'client'
          }
        }, 'user', 'contract']
      }
    }).$promise.then(res => {
      this.support = res;
      this.duration = moment(moment.duration(res.elapsed, 'minutes')._data).format("HH:mm");
      this.loaded = true;
    }).catch(() => {
      this.UI.addToast('Não foi possível encontrar o ticket especificado');
      this.$state.go('app.support.list');
    });
  };

  getFileExtension = a => {
    if (a && a.name) {
      let images = "|jpg|png|jpeg|bmp|gif|webp|";
      let documents = "|doc|docx|txt|odt|html|";
      let sheets = "|xls|xlsx|csv|ods|";
      let zips = "|zip|rar|gz|";
      let extension = a.name.split(".").pop();
      if (extension === "pdf") {
        return "file-pdf";
      } else if (images.indexOf(extension) !== -1) {
        return "file-image"
      } else if (documents.indexOf(extension) !== -1) {
        return "file-document";
      } else if (sheets.indexOf(extension) !== -1) {
        return "file-excel";
      } else if (zips.indexOf(extension) !== -1) {
        return "zip-box";
      } else {
        return "file";
      }
    }
  };

  close = () => {
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./close.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.data = {
          countTime: true
        };

        $scope.count = () => {
          return moment.duration(moment().diff(this.support.date)).asMinutes();
        };

        $scope.countFrom = () => {
          let duration = moment.duration(moment().diff(this.support.date));
          return `${parseInt(duration.asHours())}:${parseInt(duration.asMinutes() % 60)}`;
        };

        $scope.ok = () => {
          $scope.data.finished = moment();
          if ($scope.data.countTime) {
            $scope.data.elapsed = $scope.count();
          } else {
            $scope.data.elapsed = parseInt($scope.data.elapsed);
          }
          $scope.$close($scope.data);
        };

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    });

    dialog.then(res => {
      if (res) {
        let d = angular.copy(this.support);
        d.finished = res.finished;
        d.elapsed = parseInt(res.elapsed);
        this.Support.upsert(d).$promise.then(() => {
          this.UI.addToast('Ticket concluído com sucesso');
        })
      }
    });
  };

  addActivity = (keyEvent) => {
    if (keyEvent.which === 13 && this.msg) {
      this.support.notes = this.support.notes || [];
      let u = this.Auth.getUser();
      let user = {
        name: u.name,
        email: u.email,
        id: u.id,
      };
      this.support.notes.push({
        userId: u.id,
        user: user,
        description: angular.copy(this.msg)
      });
      this.msg = "";
      this.support.$save().then(() => {
        this.loadData();
        this.UI.addToast("Nota adicionada.");
      });
    }

  }

  editSummary = r => {
    let options = {
      template: require('./change-summary.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.data = r.summary;

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };
    let dialog = this.UI.showDialog(options);
    dialog.then(ok => {
      r.summary = ok.data;
      this.Support.upsert(r).$promise.then((x) => {
        this.UI.addToast("Assunto alterado com sucesso");
      }).catch((err) => {
        console.log(err);
        this.UI.addToast("Erro ao alterar Assunto");
      });
    });
  }

  edit = (r, type) => {
    let options = {
      template: require('./change-date.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.dataOptions = {
          format: 'DD-MM-YYYY HH:mm'
        };

        switch(type){
          case 0:
            $scope.data = r.request;
            break;
          case 1:
            $scope.data = r.date;
            break;
          case 2:
            $scope.data = r.finished;
            break;
        }

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }
      ]
    };
    let dialog = this.UI.showDialog(options);
    dialog.then((ok) => {
      switch(type){
        case 0:
          r.request = ok.data;
          break;
        case 1:
          r.date = ok.data;
          break;
        case 2:
          r.finished = ok.data;
          break;
      }
      this.Support.upsert(r).$promise.then((x) => {
          this.UI.addToast("Data alterada com sucesso");
        }).catch((err) => {
        console.log(err);
        this.UI.addToast("Erro ao alterar data");
      });
    })
  };

  editTime = () => {
    let tempo = this.support.elapsed;
    let options = {
      template: require('./change-time.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.tempo = tempo;

        $scope.ok = () => {
          $dialog.close($scope);
        }

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialog = this.UI.showDialog(options);
    dialog.then((ok) => {
      this.support.elapsed = ok.tempo;
      this.Support.upsert(this.support).$promise.then((x) => {
        this.UI.addToast("Tempo alterado com sucesso");
        this.loadData();
      }).catch((err) => {
        console.log(err);
        this.UI.addToast("Erro ao alterar tempo");
      });
    })
  }
}

SupportDetailsController.$inject = ['$state', 'Support', 'UIService', 'AuthenticationService'];
