export default class ChangelogAppController {
  constructor($rootScope, $state, $stateParams, ChangelogappService, UIService, Modules) {
    this.ChangelogService = ChangelogappService;
    this.UI = UIService;
    this.module = null;
    this.$state = $state;
    this.Modules = Modules;
    this.$stateParams = $stateParams;
    let env = $rootScope.status.environment;
    this.previous = {};
    this.isEditing = null;
    this.modules = null;

    this.appId = this.$stateParams.appId;

    // We need to fetch all the modules for the current app
    if (this.appId != null ) {
      this.Modules.find({
        filter: {
          where: {
            appId: this.appId,
            active: 1
          },
          order: "name ASC"
        }
      }).$promise.then((result) => {
        this.modules = result;
      });
    }

    if ($stateParams.appId && $stateParams.major && $stateParams.minor && $stateParams.patch) {

      this.isEditing = true;
      this.ChangelogService.getVersion($stateParams.appId, $stateParams.major, $stateParams.minor, $stateParams.patch).then((r) => {
        this.form = r;
        this.form.estado--;
        this.form.appId = $stateParams.appId;
        this.form.module = this.getModuleWithId(this.form.moduleId);
        this.loaded = true;
      });
    } else {
      // Only save info of previous changelog if we are adding a new one
      this.isEditing = false;
      this.ChangelogService.getVersion().then((r) => {
        this.previous = r;
      });

      // Generate clean form
      this.form = {
        appId: $stateParams.appId,
        module: null,
        major: null,
        minor: null,
        patch: null,
        git: '',
        estado: '',
        bugfixes: '',
        added: '',
        improvements: '',
        dev: true,
        staging: ($rootScope.env === 'staging') ? true : false,
        production: ($rootScope.env === 'production') ? true : false,
      };
    }
  }

  getModuleWithId = (id) => {
    for (let i = 0; i < this.modules.length; i++) {
      if (this.modules[i].id === id)
        return this.modules[i];
    }
    return null;
  };


  upsert = () => {
    if(this.form.id == null) {
      this.form.id = 0;
    }
    this.form.estado++;
    this.ChangelogService.upsert(this.form).then(res => {
      this.form = res;
      this.UI.addToast('Inserido com sucesso');
      this.$state.go('app.aplicacoes.details', ({id: this.appId}));
    }).catch(err => {
      this.UI.addToast('Erro ao inserir changelog');
    });
  }
}

ChangelogAppController.$inject = ['$rootScope', '$state', '$stateParams', 'ChangelogappService', 'UIService', 'Modules'];
