export default class DashboardController {
  constructor(App, Changelogapp, AuthenticationService) {

    this.app = null;
    this.App = App;
    this.Changelogapp = Changelogapp;
    this.versionLoading = true;
    this.user = AuthenticationService.getUser();
    this.getApp();
    this.getAppAndroid();
    this.getAppFlutter();
    this.getAppWeb();
    this.getAppiOs();
    this.getAppChangelogapp();
  }

  getApp = () => {
    this.versionLoading = true;
    this.App.find({
      filter: {
        where: {
          active: 1,
        }
      }
    }).$promise.then((res) => {
      this.numApps = res.length;
      this.versionLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  getAppAndroid = () => {
    this.versionLoading = true;
    this.App.find({
      filter: {
        where: {
          active: 1,
          plataforma: 'Android'
        }
      }
    }).$promise.then((res) => {
      this.numAppsAndroid = res.length;
      this.versionLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  getAppFlutter = () => {
    this.versionLoading = true;
    this.App.find({
      filter: {
        where: {
          active: 1,
          plataforma: 'Flutter'
        }
      }
    }).$promise.then((res) => {
      this.numAppsFlutter = res.length;
      this.versionLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  getAppWeb = () => {
    this.versionLoading = true;
    this.App.find({
      filter: {
        where: {
          active: 1,
          plataforma: 'Web'
        }
      }
    }).$promise.then((res) => {
      this.numAppsWeb = res.length;
      this.versionLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  getAppiOs = () => {
    this.versionLoading = true;
    this.App.find({
      filter: {
        where: {
          active: 1,
          plataforma: 'iOS'
        }
      }
    }).$promise.then((res) => {
      this.numAppsiOs = res.length;
      this.versionLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  getAppChangelogapp = () => {
    this.versionLoading = true;
    this.Changelogapp.find({
      filter: {
        where: {
          active: 1,
        }
      }
    }).$promise.then((res) => {
      this.numChangelogapp = res.length;
      this.versionLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };
}

DashboardController.$inject = ['App', 'Changelogapp', 'AuthenticationService'];
