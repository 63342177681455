let convert = require('color-convert');
const AMG88xx_PIXEL_ARRAY_SIZE = 64;

export default class IotListcontroller {
  constructor(Iot) {
    this.Iot = Iot;
    this.loaded = false;
    Iot.count({
      filter: {
        fields: {
          id: true
        },
        order: 'date DESC'
      }
    }).$promise.then(r => {
      this.opt = {
        min: 0,
        max: r.count,
        val: 0
      }

      this.loaded = true;
      this.change();
    });
  }

  change = () => {
    this.getData();
  }

  getData = () => {
    this.Iot.find({
      filter: {
        skip: this.opt.val,
        limit: 200,
        order: 'date DESC'
      }
    }).$promise.then(d => {
      this.data = [];
      d.forEach(a => {
        this.data.push(a.data[0]);
      })
      this.o = d[0];
      this.calibrate();
      this.createBinaryFrame();
      this.render();
      this.ready = true;
    });
  }

  render = () => {
    this.average = this.split(this.bgPixels, 8);
    this.current = this.split(this.pixelBinary, 8);
    this.temp = this.split(this.currentTemperature, 8);
  }

  calibrate = () => {
    this.bgPixels = new Array(AMG88xx_PIXEL_ARRAY_SIZE).fill(0);
    let RUNS = this.data.length;
    for (let i = 0; i < AMG88xx_PIXEL_ARRAY_SIZE; i++) {
      this.bgPixels[i] = 0;
      for (let j = 0; j < RUNS; j++) {
        this.bgPixels[i] += this.data[j][i];
      }
      this.bgPixels[i] = this.bgPixels[i] / RUNS;
    }
  }

  createBinaryFrame = () => {
    this.pixelBinary = new Array(AMG88xx_PIXEL_ARRAY_SIZE).fill(0);
    this.currentTemperature = new Array(AMG88xx_PIXEL_ARRAY_SIZE).fill(0);
    let pixelDiff = new Array(AMG88xx_PIXEL_ARRAY_SIZE).fill(0);
    let threshold = 1;

    for (let i = 0; i < AMG88xx_PIXEL_ARRAY_SIZE; i++) {
      this.currentTemperature[i] = this.o.data[0][i];
      pixelDiff[i] = this.o.data[0][i] - this.bgPixels[i];
      this.pixelBinary[i] = (pixelDiff[i] > threshold) ? 1 : 0;
    }
  }

  getOnesPercent = () => {
    let ones = 0;
    for (let i = 0; i < AMG88xx_PIXEL_ARRAY_SIZE; i++) {
      if (this.pixelBinary[i] == 1)
        ones++;
    }
    return ones * 100 / AMG88xx_PIXEL_ARRAY_SIZE;
  }

  hasPerson = () => {
    if (!this.ready)
      return "A carregar..."; 
    if (this.getOnesPercent() > 20) {
      return "Pessoa detectada!";
    } else {
      return "- ";
    }
  }

  getPersonLocation = () => {
    let colSum = new Array(8);
    let personLocation = -1;
    let highest = -1;

    for (let i = 0; i < 8; i++) {
      colSum[i] = 0;
      for (let i = 0; i < AMG88xx_PIXEL_ARRAY_SIZE; i++) {
        colSum[i] += pixels[i + j];
      }
      if (highest == -1) {
        highest = colSum[i];
        personLocation = i + 1;
      } else {
        if (colSum[i] > highest) {
          highest = colSum[i];
          personLocation = i + 1;
        }
      }
    }
    return personLocation;
  }

  split = (array, n) => {
    let [...arr] = array;
    var res = [];
    while (arr.length) {
      res.push(arr.splice(0, n));
    }
    return res;
  }
}

IotListcontroller.$inject = ['Iot'];
