export default class LoggingController {
  constructor($state, Token, UIService) {
    this.Token = Token;
    this.loaded = true;
    this.UI = UIService;
    this.logType = [
      {
        name: "Info",
        level: "INFO",
      },
      {
        name: "Erro",
        level: "ERROR",
      },
      {
        name: "Debug",
        level: "DEBUG",
      },
      {
        name: "Todos",
        level: "ALL"
      }
    ];
    this.loadData();
  }

  loadData = () => {
    this.Token.find({
      filter: {
        include: 'user',
        where: {
          created: {
            gte: moment().subtract(2,'w')
          }
        }
      }
    }).$promise.then(res => {
      this.sessions = res;
    })
  };

  closeSession = row => {
    this.UI.showConfirm('Deseja terminar esta sessão?').then(res => {
      if (res) {
        row.ttl = 0;
        row.$save().then(() => {
          this.UI.addToast("Sessão terminada");
        });
        this.loadData();
      }
    }).catch(() => {});
  };

  expiresAt =(row) => {
    return moment(row.created).add(row.ttl,'s').format("DD-MM-YYYY HH:mm");
  }
}

LoggingController.$inject = ['$state', 'Token', 'UIService'];
