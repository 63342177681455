import angular from 'angular';
import pmdTabs from "./directives/tabs/pmdTabs";
import pmdPickers from "./directives/pickers/pmdPickers";
import pmdAlert from './directives/toasts/pmdAlert';
import pmdAccordion from "./directives/accordion/pmdAccordion";
import pmdProgress from "./directives/progress/pmdProgress";
import pmdDialog from "./directives/dialogs/pmdDialog";
import pmdTooltip from "./directives/others/pmdTooltip";
import pmdAvatar from "./directives/others/pmdAvatar";
import pmdCalendar from "./directives/others/pmdCalendar";
import pmdTypeahead from "./directives/inputs/pmdTypeahead";
import pmdSelect from "./directives/inputs/pmdSelect";
import pmdScrollbar from "./directives/others/pmdScrollbar";
import pmdSlider from "./directives/others/pmdSlider";
import pmdInfiniteScroll from "./directives/others/pmdInfiniteScroll";

export default angular.module('app.interface.directives', [pmdTabs, pmdPickers, pmdAlert, pmdAccordion, pmdProgress, pmdDialog, pmdTooltip, pmdAvatar, pmdCalendar, pmdSelect, pmdTypeahead, pmdScrollbar, pmdSlider, pmdInfiniteScroll])
  .run(['$rootScope', ($rootScope) => {
  $rootScope.uiBase = {
    name: 'Børk',
    description: 'Streamline Changelog App in AngularJS',
    version: '1.0.3'
  };
  }])
  .name;
