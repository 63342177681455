export function routes($stateProvider) {

  $stateProvider
    .state('app.clients', {
      url: '/clients',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.clients.list', {
      url: '/',
      template: require('./list/list.view.html'),
      controller: 'ClientListController',
      controllerAs: 'vm'
    })
    .state('app.clients.details', {
      url: '/{id}',
      template: require('./details/details.view.html'),
      controller: 'ClientDetailsController',
      controllerAs: 'vm'
    })
}

routes.$inject = ['$stateProvider'];
