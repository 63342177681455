import angular from 'angular';

export default angular.module('app.interface.typeahead', [])
  .filter('highlight', ['$sce', function ($sce) {
    return function (text, phrase) {
      text = text.toString();
      if (phrase) text = text.replace(new RegExp('(' + phrase + ')', 'i'),
        '<span class="highlighted">$1</span>');
      return $sce.trustAsHtml(text);
    }
  }])
  .directive('pmdTypeahead', ['$compile', '$filter', '$timeout', function ($compile, $filter, $timeout) {
    return {
      restrict: 'E',
      require: '?ngModel',
      scope: {
        options: '=',
        show: '@',
        label: '@',
        inputType: '@',
        inputRequired: '@'
      },
      link: (scope, element, attrs, ngModel) => {

        function uuidv4() {
          return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          )
        };

        scope.input = scope.inputType || "text";
        scope.required = scope.inputRequired || false;

        scope.min = 0;

        scope.floating = angular.isDefined(attrs.floating) ?
          scope.$parent.$eval(attrs.floating) : false;

        scope.showAll = true;

        scope.$watch(function () {
          return ngModel.$modelValue;
        }, function (value) {
          if (angular.isDefined(value)) {
            let list;
            try {
              list = JSON.parse(angular.toJson(scope.options));
            } catch(err) {
              list = [];
            }
            if (list && scope.show) {
              for (let i = 0; i < list.length; i++) {
                let o = list[i];
                if (angular.equals(o, value)) {
                  scope.select(list.indexOf(o));
                  break;
                }
              }
            } else {
              scope.term = ngModel.$viewValue;
            }
          } else {
            scope.term = ngModel.$viewValue;
          }
        }, true);

        if (ngModel.$viewValue) {
          scope.options.forEach(o => {
            if (angular.equals(o, ngModel.$viewValue)) {
              scope.selected = o[scope.show];
            }
          })
        } else {
          scope.selected = '';
        }

        // TODO: Think if we will handle key events

        scope.select = function (idx) {
          let select;
          if (idx === 'empty-pmd-typeahead-condition') {
            select = {
              id: 0
            };
            angular.merge(select, ngModel.$viewValue);
            select[scope.show] = scope.term;
          } else {
            select = scope.options[idx];
          }
          if (select) {
            scope.term = select[scope.show];
            scope.oldTerm = select[scope.show];
            scope.onFilter();
            scope.selected = select[scope.show];
            ngModel.$setViewValue(select);
            ngModel.$render();
          }
        };

        scope.isSelected = function (r) {
          if (scope.selected === '')
            return false;
          return angular.equals(ngModel.$viewValue, r);
        };

        scope.hasSelection = function () {
          return scope.selected !== '';
        };

        scope.clear = function () {
          scope.selected = '';
          ngModel.$setViewValue(undefined);
          ngModel.$render();
        };

        scope.oldTerm = '';
        scope.term = "";

        scope.clickToSelect = (r) => {
          let index = scope.options.indexOf(r);
          scope.select(index);
        };

        scope.onFilter = function () {
          scope.clear();
          scope.filteredOptions = [];
          if (scope.options) {
            for (let i = 0; i < scope.options.length; i++) {
              let o = scope.options[i];
              scope.term = scope.term || "";
              if (o[scope.show].toString().toLowerCase().indexOf(scope.term.toString().toLowerCase()) === 0) {
                scope.filteredOptions.push(o);
              }
              if (angular.equals(o[scope.show].toString().toLowerCase(), scope.term.toString().toLowerCase())) {
                scope.term = o[scope.show];
                scope.oldTerm = o[scope.show];
                scope.selected = o[scope.show];
                ngModel.$setViewValue(o);
                ngModel.$render();
                break;
              }
            }
            if (scope.filteredOptions.length === 0 && !angular.equals(scope.term, scope.oldTerm)) {
              // Null selection, pass this to alert the system that will create a "invalid" object based on description writen
              scope.select('empty-pmd-typeahead-condition');
            }
          }
        };

        scope.doClick = (event) => {
          if (event.keyCode === 9)
            angular.element('#' + scope.elId).triggerHandler('click');
          angular.element('#' + scope.elId).click();
          if (event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
            // Select first one on the list
            if (scope.filteredOptions.length > 0) {
              ngModel.$setViewValue(scope.filteredOptions[0]);
              ngModel.$render();
            }
          }
        };

        scope.elId = uuidv4();

        scope.onFilter();

        let template =
          '<div class="pmd-textfield form-group" ng-class="{\'pmd-textfield-floating-label\': floating, \'pmd-textfield-floating-label-completed\': term.toString().length > 0 }">\n' +
          '<label ng-bind="label"></label>\n' +
          '<input type="hidden" ng-model="' + attrs.ngModel + '"/>\n' +
          '<input id="{{elId}}" type="{{inputType}}" ng-required="required" ng-model="term" ng-keypress="doClick($event)" ng-change="onFilter()" data-toggle="dropdown" class="noselect form-control dropdown-toggle"><span class="pmd-textfield-focused"></span>\n' +
          '  <ul class="dropdown-menu pmd-d">\n' +
          '    <li ng-if="showAll" ng-repeat="r in options track by $index" ng-click="clickToSelect(r)" ng-class="{\'active\': isSelected(r)}"><a ng-bind-html="r[show] | highlight: term"></a></li>\n' +
          '    <li ng-if="!showAll" ng-repeat="r in filteredOptions track by $index" ng-click="clickToSelect(r)" ng-class="{\'active\': isSelected(r)}"><a ng-bind-html="r[show] | highlight: term"></a></li>\n' +
          '    <li ng-if="!options.length && !hideEmpty" class="disabled"><a>Sem resultados</a></li>\n'
          '</ul>';

        element.replaceWith($compile(template)(scope));
      }
    }
  }])
  .run(['$rootScope', ($rootScope) => {
    $rootScope.uiModules = $rootScope.uiModules || [];
    let properties = {
      name: 'Typeahead',
      description: 'Fast and fully-featured autocomplete',
      version: '1.0.0'
    };
    if ($rootScope.uiModules.indexOf(properties) === -1) {
      $rootScope.uiModules.push(properties);
    }
  }])
  .name;
