import {routes} from "./app.routes";
import AppController from './directory/app.controller'
import AppControllerDetails from './details/details.controller'
import DirectiveLoader from "../interface/directive.loader";
import ChangelogappService from "./details/changelogapp.service";
import ChangelogAppController from "./details/changedetails/changeDetails";
import AppChangelogController from "./details/details.controller";


export default angular.module('app.aplicacoes', [DirectiveLoader])
  .config(routes)
  .service('ChangelogappService', ChangelogappService)
  .controller('AppController', AppController)
  .controller('ChangelogAppController', ChangelogAppController)
  .controller('AppControllerDetails', AppControllerDetails)
  .controller('AppChangelogController', AppChangelogController)
  .name;
