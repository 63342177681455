window.$ = window.jQuery;
/* Angular Dependencies */
import angular from 'angular';

let uiRouter = require("@uirouter/angularjs").default;
import '@uirouter/angularjs/release/stateEvents';
import 'angular-file-upload';

let uiSelect = require('ui-select');
import angularLocalePT from "angular-i18n/pt-pt";
import ngResource from "angular-resource";
//import ngSanitize from "angular-sanitize";
require('textangular/dist/textAngular-sanitize');
import textAngular from "textangular";

require('angular-heremaps/dist/angular-heremaps');

/* Template modules */
import config from './app.config';
import {
  ApplicationInterceptor
} from "./app.interceptor";
import {
  ngTableModule
} from 'ng-table/bundles/ng-table';
/* Client Modules */
import lbServices from './modules/loopback/lbServices';
import Interface from './modules/interface';
import Authentication from './modules/authentication';
import Administration from './modules/administration';
import Authorization from './modules/authorization';
import App from './modules/aplicacoes';
import Public from './modules/public';
import Support from './modules/support';
import Clients from './modules/clients';
import IoT from './modules/iot';
// Set moment locale to Portugal globally. It works :)
moment.locale('pt');
// All CSS requirements by directives and others shall be imported HERE
require('textangular/dist/textAngular.css');
require('ng-table/bundles/ng-table.min.css');
require('ui-select/dist/select.min.css');
/* App global inject */
let app = angular.module('app', [
  uiRouter,
  'ui.router.state.events',
  ngResource,
  //ngSanitize,
  textAngular,
  angularLocalePT,
  uiSelect,
  'angularFileUpload',
  ngTableModule.name,
  lbServices,
  Interface,
  Authentication,
  Authorization,
  Administration,
  Public,
  Support,
  Clients,
  App,
  IoT
]);

app.config(config);
app.config(function () {
  angular.lowercase = angular.$$lowercase;
});
app.run(ApplicationInterceptor);
app.config(['uiSelectConfig', function (uiSelectConfig) {
  uiSelectConfig.theme = 'bootstrap';
  uiSelectConfig.resetSearchInput = false;
  uiSelectConfig.skipFocusser = true;
}]);
app.run(['ngTableDefaults', function (ngTableDefaults) {
  ngTableDefaults.params.setPager = false;
  ngTableDefaults.settings.counts = [10, 25, 50, 100];
  ngTableDefaults.params.page = 1;
}]);
app.run(['$templateCache', function ($templateCache) {
  $templateCache.put('ng-table/filters/text.html', require('./modules/interface/templates/text.html'));
  $templateCache.put('ng-table/filters/number.html', require('./modules/interface/templates/number.html'));
  $templateCache.put('ng-table/filters/select.html', require('./modules/interface/templates/select.html'));
  $templateCache.put('ng-table/pager.html', require('./modules/interface/templates/pager.html'));
}]);

// Fix bug in re-adding same file
app.config(['$provide', function ($provide) {
  $provide.decorator('FileSelect', ['$delegate', function (FileSelect) {
    FileSelect.prototype.isEmptyAfterSelection = function () {
      return true
    };
    return FileSelect;
  }])
}]);

// TextAngular config
app.config(['$provide', function ($provide) {
  $provide.decorator('taOptions', ['taRegisterTool', 'Dialog', '$rootScope', '$delegate', function (taRegisterTool, Dialog, $rootScope, taOptions) {
    // Tools added by tribeiro
    taRegisterTool('uploadImage', {
      iconclass: " mdi mdi-image",
      tooltiptext: 'Upload an image',
      action: function (deferred, restoreSelection) {
        Dialog.dialog({
          size: 'lg',
          template: require('./modules/interface/templates/upload.html'),
          controller: ['$scope', '$rootScope', '$http', 'FileUploader', 'UIService', ($scope, $rootScope, $http, FileUploader, UIService) => {

            $scope.images = $rootScope.taImages || [];

            $scope.uploader = new FileUploader({
              url: '/api/assets/upload/attachments',
              queueLimit: 1
            });

            $scope.uploader.onSuccessItem = (file, response, status, headers) => {
              if (response.result) {
                $scope.image = {
                  container: response.result.container,
                  year: response.result.year,
                  month: response.result.month,
                  name: response.result.name,
                  size: response.result.size,
                };
              }
              $scope.$close($scope.image);
            };

            $scope.ok = () => {
              $scope.uploader.uploadAll();
            };

            $scope.uploader.filters.push({
              name: 'imageFilter',
              fn: function (item /*{File|FileLikeObject}*/ , options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|webp|'.indexOf(type) !== -1;
              }
            });
          }],
        }).then(res => {
          if (res) {
            $rootScope.taImages.push(res);
            // Inserting into text-angular
            console.log("Adding image!");
            document.execCommand('insertHtml', true, `<img class="img-responsive" src="/api/assets/download/${res.container}/${res.year}/${res.month}/${res.name  }">`);
          }
          restoreSelection(); // puts the selection back in the correct place
          deferred.resolve();
        }).catch(() => {
          deferred.resolve();
        });
      }
    });

    // $delegate is the taOptions we are decorating
    // here we override the default toolbars and classes specified in taOptions.
    taOptions.forceTextAngularSanitize = true; // set false to allow the textAngular-sanitize provider to be replaced
    taOptions.keyMappings = []; // allow customizable keyMappings for speci alized key boards or languages
    taOptions.toolbar = [
      ['bold', 'italics', 'underline'],
      ['ul', 'outdent', 'indent'],
      ['redo', 'undo', 'clear'],
      ['uploadImage', 'insertVideo', 'html'],
    ];
    taOptions.classes = {
      focussed: '',
      toolbar: 'btn-toolbar',
      toolbarGroup: 'btn-group',
      toolbarButton: 'btn btn-xs btn-default pmd-btn-flat pmd-ntn-raised pmd-ripple-effect',
      toolbarButtonActive: 'active',
      disabled: 'disabled',
      textEditor: 'form-control',
      htmlEditor: 'form-control',
    };
    return taOptions; // whatever you return will be the taOptions
  }]);
  // Replace css icons
  $provide.decorator('taTools', ['$delegate', function (taTools) {
    taTools.bold.iconclass = ' mdi mdi-format-bold';
    taTools.italics.iconclass = 'mdi mdi-format-italic';
    taTools.underline.iconclass = ' mdi mdi-format-underline';
    taTools.ul.iconclass = ' mdi mdi-format-list-bulleted';
    taTools.indent.iconclass = ' mdi mdi-format-indent-increase';
    taTools.outdent.iconclass = ' mdi mdi-format-indent-decrease';
    taTools.undo.iconclass = ' mdi mdi-undo';
    taTools.redo.iconclass = ' mdi mdi-redo';
    taTools.clear.iconclass = ' mdi mdi-format-clear';
    taTools.html.iconclass = ' mdi mdi-code-tags';
    taTools.insertVideo.iconclass = ' mdi mdi-youtube';
    return taTools;
  }]);
}]);
