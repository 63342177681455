export function routes($stateProvider) {
  $stateProvider
    .state('app.iot', {
      url: '/iot',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.iot.list', {
      url: '?{page:int}&{type:int}&{term:string}',
      template: require('./list/view.html'),
      controller: 'IotListcontroller',
      controllerAs: 'vm',
    })
  ;
}

routes.$inject = ['$stateProvider'];
