import angular from 'angular';
import AuthorizationService from './authorization.service';

export default angular.module('app.authorization', [])
  .service('AuthorizationService', AuthorizationService)
  .directive('permission', ['AuthorizationService', AuthorizationService => ({
    restrict: 'A',
    scope: {
      permission: '@',
      group: '@'
    },
    link: (scope, elem, attrs) => {
      if (!_.isEmpty(scope.permission)) {
        if (AuthorizationService.canPerform(scope.permission)) {
          elem.show();
        } else {
          elem.hide();
        }
      }
      if (!_.isEmpty(scope.group)) {
        if (AuthorizationService.belongsTo(scope.group)) {
          elem.show();
        } else {
          elem.hide();
        }

      }
    }
  })])
  .name;
