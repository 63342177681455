 export default class AppControllerDetails {
  constructor($http, UIService, Modules, $stateParams, NgTableParams, Authorization, ChangelogService, App, Changelogapp, ChangelogappService, FileUploader) {
    this.UI = UIService;
    this.$http = $http;
    this.Changelogapp = Changelogapp;
    this.Modules = Modules;
    this.ChangelogappService = ChangelogappService;
    this.App = App;
    this.app = null;
    this.modules = null;
    this.id = $stateParams.id;
    this.FileUploader = FileUploader;
    this.NgTableParams = NgTableParams;
    this.versionLoading = true;
    this.$stateParams = $stateParams;
    this.Changelog = ChangelogService;
    this.filter = "";
    this.loaded = false;
    this.showAll = $stateParams.expanded;
    this.Authorization = Authorization;
    this.limit = this.showAll ? undefined : 3;
    this.latestProdVersion = null;
    this.latestStagingVersion = null;
    this.latestDevVersion = null;
    this.latestApk = null;
    this.getApp(this.id);
    this.getModulesForApp(this.id);
  };

  load = (appId) => {
    this.ChangelogappService.getVersion(appId, this.$stateParams.major, this.$stateParams.minor, this.$stateParams.patch).then((version) => {
      this.version = version;
      this.ChangelogappService.getVersions(appId).then(r => {
        this.versions = r;
        this.loaded = true;
        this.versionLoading = false;
        this.latestProdVersion = this.findProdVersion();
        this.latestStagingVersion = this.findStagingVersion();
        this.latestDevVersion = this.findDevVersion();
        this.latestApk = this.findLatestApk();
      });
    }).catch(err => {
      this.UI.addToast('Não foi possível carregar o changelog. Por favor, tente mais tarde!');
    });
  };

  findLatestApk = () => {
    for (let i = 0; i < this.versions.length; i++) {
      if (this.versions[i].filename)
        return this.versions[i].filename;
    }
    return null;
  };


  isSelected = (v) => {
    return (v.major == this.version.major && v.minor == this.version.minor && v.patch == this.version.patch);
  };

  limitTo = () => {
    if (this.filter.length === 0) {
      if (!this.showAll) {
        this.limit = undefined;
        this.showAll = true;
      } else {
        this.limit = 3;
        this.showAll = false;
      }
    }
  };

  isAllowed = () => {
    return this.Authorization.canPerform('changelog');
  };

  filtering = () => {
    if (this.filter.length !== 0) {
      this.showAll = true;
      this.limit = undefined;
    } else {
      this.limitTo();
    }
  };

  findDevVersion = () => {
    for (let i = 0; i < this.versions.length; i++) {
      if (this.versions[i].dev)
        return this.versions[i];
    }
    return null;
  };

  findProdVersion = () => {
    for (let i = 0; i < this.versions.length; i++) {
      if (this.versions[i].production)
        return this.versions[i];
    }
    return null;
  };

  findStagingVersion = () => {
    for (let i = 0; i < this.versions.length; i++) {
      if (this.versions[i].staging)
        return this.versions[i];
    }
    return null;
  };

  createModule = () => {
    let options = {
      size: 'md',
      template: require('./moduleAdd.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.module = {};
        $scope.label = "Adicionar módulo";
        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate.then((ok) => {

      console.log(this.id);

      this.Modules.create({
        id: 0,
        name: ok.module.name,
        observacoes: ok.module.observacoes,
        appId: this.id,
        active: 1
      }).$promise.then((res) => {
        this.getModulesForApp(this.id);
        this.UI.addToast("Módulo adicionado com sucesso!");
      }).catch(err => {
        this.UI.addToast("Erro na criação do módulo!");
        console.log(err);
      });
    }).catch((error) => {
      if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
        console.log(error);
    });
  };

  removeModule = (a) => {
    this.Modules.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((res) => {
      let options = {
        size: 'md',
        template: require('./modalRemoverModulo.view.html'),
        controller: ['$scope', '$dialog', ($scope, $dialog) => {

          $scope.ok = () => {
            $dialog.close(res);

          };
          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      };

      let dialogRemove = this.UI.showDialog(options);

      dialogRemove.then((result) => {
        result.active = 0;
        result.$save().then((r) => {
          this.UI.addToast("Módulo removido com sucesso!");
          this.getModulesForApp(this.id);
        }).catch((error) => {
            console.log(error);
          }
        ).catch((error) => {
          console.log(error);
        })
      }).catch((err) => {
        console.log(err);
      });
    })
  };

  editModule = (m) => {
    let options = {
      size: 'md',
      template: require('./moduleAdd.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.module = {};
        $scope.module.id = m.id;
        $scope.module.name = m.name;
        $scope.module.observacoes = m.observacoes;

        $scope.label = "Editar módulo";

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((a) => {

      this.Modules.findOne({
        filter: {
          where: {
            id: a.module.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.name = a.module.name;
        res.id = a.module.id;
        res.observacoes = a.module.observacoes;
        res.$save().then((r) => {
          this.getModulesForApp(this.id);
          this.UI.addToast("Módulo editado com sucesso!");
        }).catch(err => {
          this.UI.addToast("Erro na edição do módulo!");
          console.log(err);
        });
      }).catch((error) => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
          console.log(error);
      });
    });
  };


  getApp = (id) => {
    this.versionLoading = true;
    this.App.findOne({
      filter: {
        where: {
          id: id,
          active: 1
        },
        include: {
          relation: 'AppClient'
        }
      }
    }).$promise.then((res) => {
      this.app = res;
      this.load(this.app.id);
    }).catch((error) => {
      console.log(error);
    })
  };


  getModulesForApp = (id) => {
    this.versionLoading = true;
    this.Modules.find({
      filter: {
        where: {
          appId: id,
          active: 1
        },
        include: 'appModule'
      }
    }).$promise.then((res) => {
      this.modules = res;

      this.tableModules = new this.NgTableParams({
        count: 20,
        sorting: {
          name: 'asc'
        }
      }, {
        dataset: this.modules
      });

    }).catch((error) => {
      console.log(error);
    })
  };

  generateUUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  editAnexo = () => {
    let self = this;
    let options = {
      size: 'md',
      template: require('./modal-addapk.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.label = "Editar APK";

        $scope.uploader = new self.FileUploader({
          url: '/api/Upload/files/upload',
          queueLimit: 1,
          formData: [
            {
              key: 'value'
            }
          ]
        });

        let filter = {
          name: 'verifyAPK',
          fn: function (item, options) {
            let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
            return '|vnd.android.package-archive|'.indexOf(type) !== -1;
          }
        };

        $scope.uploader.filters.push(filter);

        $scope.uploader.onAfterAddingFile = (fileItem) => {
          if ($scope.uploader.queue.length > 1)
            $scope.uploader.queue.splice(0, $scope.uploader.queue.splice.length - 1);
        };

        $scope.uploader.onWhenAddingFileFailed = (fileItem) => {
          self.UI.addToast("Por favor, carregue um ficheiro em formato APK");
        };

        $scope.uploader.onSuccessItem = (response, status, headers) => {
          self.UI.addToast("Ficheiro editado com sucesso");
        };

        $scope.uploader.onErrorItem = (response, status, headers) => {
          self.UI.addToast("O ficheiro não foi editado com sucesso");
        };

        $scope.uploader.onBeforeUploadItem = (item) => {
          // Keep the same filename, just change the file itself
          item.file.name = self.version.filename;
        };

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((ok) => {
      this.versionLoading = true;
      this.insertNewAnexo(ok);
    }).catch((error) => {
      if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
        console.log(error);
    });
  };

  addAnexo = () => {
    let self = this;
    let options = {
      size: 'md',
      template: require('./modal-addapk.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.label = "Novo APK";

        // Generate new file name to apply later
        $scope.apkName = self.app.package + "_" + "v_" + self.version.major + "." + self.version.minor + "." + self.version.patch + ".apk";

        $scope.uploader = new self.FileUploader({
          url: '/api/Upload/files/upload',
          queueLimit: 1,
          formData: [
            {
              key: 'value'
            }
          ]
        });

        let filter = {
          name: 'verifyAPK',
          fn: function (item, options) {
            let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
            return '|vnd.android.package-archive|'.indexOf(type) !== -1;
          }
        };

        // Inserir filtro
        $scope.uploader.filters.push(filter);

        $scope.uploader.onAfterAddingFile = (fileItem) => {
          if ($scope.uploader.queue.length > 1)
            $scope.uploader.queue.splice(0, $scope.uploader.queue.splice.length - 1);
        };

        $scope.uploader.onWhenAddingFileFailed = (fileItem) => {
          self.UI.addToast("Por favor, carregue um ficheiro em formato APK");
        };

        $scope.uploader.onSuccessItem = (response, status, headers) => {
          self.UI.addToast("Ficheiro carregado com sucesso");
        };

        $scope.uploader.onErrorItem = (response, status, headers) => {
          self.UI.addToast("O ficheiro não foi carregado com sucesso");
        };

        $scope.uploader.onBeforeUploadItem = (item) => {
          item.file.name = $scope.apkName;
        };

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((ok) => {
      this.versionLoading = true;
      this.insertNewAnexo(ok);
    }).catch((error) => {
      if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
        console.log(error);
    });
  };



  insertNewAnexo = (ok) => {
    this.Changelogapp.findOne({
      filter: {
        where: {
          appId: this.id,
          major: this.version.major,
          minor: this.version.minor,
          patch: this.version.patch,
          active: 1
        }
      }
    }).$promise.then((c) => {
      // If there is no file yet, just upload it
      if (c.filename == null) {
        c.filename = ok.apkName;
      } else {
        // There's a file already there. Change it.
        c.filename = ok.apkName;
      }
      ok.uploader.uploadAll();
      c.$save().then((res) => {
        this.getApp(this.id);
      }, (error) => {
        console.log(error);
        this.UI.addToast("Erro na gravação do APK. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("De momento não é possível adicionar o APK. Por favor tente mais tarde.");
    });
  };

  confirmRemoveAnexo = () => {
    let options = {
      size: 'md',
      template: require('./modal-removeapk.view.html'),
      controller: ['$scope', '$dialog', ($scope, $dialog) => {

        $scope.ok = () => {
          $dialog.close(true);
        };
        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogRemove = this.UI.showDialog(options);
    dialogRemove.then((result) => {
      this.removeAnexo();
    }).catch((error) => {
      if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
        console.log(error);
    });
  };

  removeAnexo = () => {
    this.ChangelogappService.getVersion(this.id, this.version.major, this.version.minor, this.version.patch).then((res) => {

      let fileToDelete = angular.copy(res.filename);
      // Put filename to null to register the deletion in the database
      res.filename = null;
      res.$save().then((r) => {
        // Delete file from storage
        this.$http({
          method: 'DELETE',
          url: '/api/Upload/files/files/' + fileToDelete
        }).then((result) => {
          // File was removed
        }, (error) => {
          console.log(error);
          // File was not removed
        });
        // Either way, the database is fixed, so carry on updating
        this.UI.addToast("APK removido com sucesso!");
        this.getApp(this.id);
      }).catch((error) => {
        this.UI.addToast("Erro na eliminação do APK. Por favor tente mais tarde.");
      });
    });
  }


  generateUUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  updateAnexo = (ok) => {
    this.App.findOne({
      filter: {
        where: {
          id: ok.app.id,
          active: 1
        }
      }
    }).$promise.then((c) => {
      // If there is no file yet, just upload it
      if (c.logotipo == null) {
        c.logotipo = ok.newFileName;
      } else {
        // There's a file already there. Change it.

        if (ok.newFileName) {
          // Remove old photo
          this.$http.delete('/api/Upload/files/files/' + c.logotipo);
          c.logotipo = ok.newFileName;
        }
        else
          c.logotipo = null;
      }
      ok.uploader.uploadAll();
      c.$save().then((res) => {
        this.getApp(this.id);
      }, (error) => {
        this.UI.addToast("Erro na gravação da imagem. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log("ERRO: ", error);
      this.UI.addToast("De momento não é possível adicionar a imagem. Por favor tente mais tarde.");
    });
  };


  editAppDetails = (appEdit) => {
    let self = this;
    let options = {
      size: 'md',
      template: require('./modal-editarDetails.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.app = {};
        $scope.app.id = appEdit.id;
        $scope.app.nome = appEdit.nome;
        $scope.app.descricao = appEdit.descricao;
        $scope.app.package = appEdit.package;
        $scope.app.logotipo = appEdit.logotipo;
        $scope.app.urlDev = appEdit.urlDev;
        $scope.app.urlProd = appEdit.urlProd;
        $scope.app.urlStaging= appEdit.urlStaging;
        $scope.plataformaOptions = [{option: 'Android'}, {option: 'iOS'}, {option: 'Flutter'}, {option: 'Web'}];
        $scope.plataformaOption = {option: appEdit.plataforma};
        $scope.estadoOptions = [{option: 'Ativo'}, {option: 'Inativo'}];
        $scope.estadoOption = {option: appEdit.estado ? 'Ativo' : 'Inativo'};
        $scope.label = "Editar aplicação";
        $scope.newFileName = '';

        $scope.uuid = self.generateUUID();

        $scope.uploader = new self.FileUploader({
          url: '/api/Upload/files/upload',
          queueLimit: 1,
          formData: [
            {
              key: 'value'
            }
          ]
        });

        let filter = {
          name: 'verifyIMG',
          fn: function (item, options) {
            return item.type.indexOf("image/") !== -1;
          }
        };

        $scope.uploader.filters.push(filter);

        $scope.uploader.onAfterAddingFile = (item) => {
          let partes = item.file.name.split(".");
          $scope.newFileName = $scope.uuid + "." + partes[partes.length - 1];

          if ($scope.uploader.queue.length > 1)
            $scope.uploader.queue.splice(0, $scope.uploader.queue.splice.length - 1);
        };

        $scope.uploader.onWhenAddingFileFailed = (img) => {
          self.UI.addToast("Por favor, carregue uma imagem");
        };

        $scope.uploader.onErrorItem = (response, status, headers) => {
          self.UI.addToast("A imagem não foi carregada com sucesso");
        };

        $scope.uploader.onBeforeUploadItem = (item) => {
          item.file.name = $scope.newFileName;
        };

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((a) => {

      this.App.findOne({
        filter: {
          where: {
            id: a.app.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.nome = a.app.nome;
        res.descricao = a.app.descricao;
        res.package = a.app.package;
        res.urlDev = a.app.urlDev;
        res.urlProd = a.app.urlProd;
        res.urlStaging = a.app.urlStaging;
        res.plataforma = a.plataformaOption.option;
        res.estado = a.estadoOption.option;
        res.logotipo = a.app.logotipo;
        if (a.estadoOption.option === 'Ativo') {
          res.estado = 1;
        } else {
          res.estado = 0;
        }
        res.$save().then((r) => {
          if (a.newFileName)
            this.updateAnexo(a);
          else
            this.getApp(this.id);
          this.UI.addToast("Aplicação editada com sucesso!");
        }).catch(err => {
          this.UI.addToast("Erro na edição de aplicação!");
          console.log(err);
        });
      }).catch((error) => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
          console.log(error);
      });
    });
  };



};

AppControllerDetails.$inject = ['$http', 'UIService', 'Modules', '$stateParams', 'NgTableParams', 'AuthorizationService', 'ChangelogService', 'App', 'Changelogapp', 'ChangelogappService', 'FileUploader'];
