import angular from 'angular';
import {routes} from './routes'
import SupportListController from "./list/list.controller";
import SupportDetailsController from "./details/details.controller";
import SupportAddController from "./new/new.controller";


export default angular.module('app.support', [])
  .config(routes)
  .controller('SupportListController', SupportListController)
  .controller('SupportDetailsController', SupportDetailsController)
  .controller('SupportAddController', SupportAddController)
  .filter('moment', function () {
    return function (input, momentFn /*, param1, param2, ...param n */) {
      let args = Array.prototype.slice.call(arguments, 2),
        momentObj = moment(input);
      return momentObj[momentFn].apply(momentObj, args);
    };
  })

  .filter('timeAgo', () => {
    return (date) => {
      let d = moment(date);
      if (d.isValid()) {
        // If it's same day
        if (d.isSame(moment(), 'd')) {
          return d.format('HH:mm');
        } else {
          return d.fromNow();
        }
      } else {
        return date;
      }
    }
  })
  .name;
