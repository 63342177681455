export default class ChangelogService {
  constructor($rootScope, $q, Changelog, $stateParams, UIService, Authorization) {
    this.$rootScope = $rootScope;
    this.$q = $q;
    this.Changelog = Changelog;
    this.Authorization = Authorization;
    this.UI = UIService;
    this.data = [];
    this.status = $rootScope.status;

    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      this.status = data;
      $rootScope.status = data;
    });

  }

  loadData = () => {
    let defer = this.$q.defer();

    // Filter by NODE_ENV version
    let env = this.status.environment;

    let query = {};

    if (env === 'staging') {
      query = {
        staging: true
      };
    } else if (env === 'production') {
      query = {
        production: true
      };
    } else {
      query = {
        dev: true
      };
    }

    // Get the current version on the server
    const current = this.status.version;
    if (current) {
      let split = current.split('.');
      // If user doesn't have changelog version can only see
      if (!this.Authorization.canPerform('changelog')) {
        query.partsum = {
          lte: this.calcPartsum(split[0], split[1], split[2])
        }
      }
    }

    query.estado = {
      gte: this.Authorization.canPerform('changelog') ? 1 : 2
    };

    this.Changelog.find({
      filter: {
        where: query,
        order: 'partsum DESC'
      }
    }).$promise.then(res => {
      res.forEach(r => {
        r.string = r.major + "." + r.minor + "." + r.patch;
      });
      this.data = res;
      defer.resolve(res);
    }).catch(err => {
      defer.reject(z);
    });
    return defer.promise;
  };

  getVersion = (major, minor, patch) => {

    let found = null;

    let defer = this.$q.defer();
    this.loadData().then((res) => {
      if (angular.isUndefined(major) || angular.isUndefined(minor) || angular.isUndefined(patch)) {
        found = res[0];
      } else {
        res.forEach(r => {
          if (r.major == major && r.minor == minor && r.patch == patch) {
            found = r;
          }
        });
      }
      defer.resolve(found);
    });

    return defer.promise;
  };

  getVersions = () => {
    let defer = this.$q.defer();
    this.loadData().then(r => defer.resolve(r)).catch(err => defer.reject(err));
    return defer.promise;
  };

  calcPartsum = (major, minor, patch) => major * Math.pow(10, 6) + minor * Math.pow(10, 3) + patch;

  upsert = (data) => {
    let defer = this.$q.defer();
    // Calculate partsum automatically
    data.partsum = this.calcPartsum(data.major, data.minor, data.patch);
    this.Changelog.upsert(data).$promise.then(res => defer.resolve(res)).catch(err => defer.reject(err));
    return defer.promise;
  }
}

ChangelogService.$inject = ['$rootScope', '$q', 'Changelog', '$stateParams', 'UIService', 'AuthorizationService'];
