export default class AppController {
  constructor(UIService, App, Client, NgTableParams, $rootScope, $state, $filter, FileUploader, $http) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$http = $http;
    this.$filter = $filter;
    this.FileUploader = FileUploader;
    this.UI = UIService;
    this.App = App;
    this.apps = null;
    this.clients = null;
    this.appsAt = null;
    this.appsIn = null;
    this.NgTableParams = NgTableParams;
    this.Client = Client;
    this.appLoading = true;
    this.getAppActive();
    this.getAppInactive();
    this.getClient();
  }

  getClient = () => {
    this.Client.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.clients = res;
    }).catch((error) => {
      console.log(error);
    })
  };

   generateUUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  insertNewAnexo = (ok) => {
    this.App.findOne({
      filter: {
        where: {
          id: ok.app.id,
          active: 1
        }
      }
    }).$promise.then((c) => {
      // There's a file already there. Change it.
      if (ok.newFileName) {
        // Remove old photo
        this.$http.delete('/api/Upload/files/files/' + c.logotipo);
        c.logotipo = ok.newFileName;
        ok.uploader.uploadAll();
      } else {
        c.logotipo = null;
      }
      c.$save().then((res) => {
        this.getAppActive();
        this.getAppInactive();
      }, (error) => {
        this.UI.addToast("Erro na gravação da imagem. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("De momento não é possível adicionar a imagem. Por favor tente mais tarde.");
    });
  };

  createApp = () => {
    let self = this;
    let options = {
      size: 'md',
      template: require('./modal-adicionar.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.app = {};
        $scope.app.client = {};
        $scope.label = "Adicionar aplicação";
        $scope.plataformaOptions = [{option: 'Android'}, {option: 'iOS'}, {option: 'Flutter'}, {option: 'Web'}];
        $scope.plataformaOption = {};
        $scope.estadoOptions = [{option: 'Ativo'}, {option: 'Inativo'}];
        $scope.clientOptions = self.clients;
        $scope.clientOption = {};
        $scope.estadoOption = {};
        $scope.newFileName = '';

        $scope.uuid = self.generateUUID();

        $scope.uploader = new self.FileUploader({
          url: '/api/Upload/files/upload',
          queueLimit: 1,
          formData: [
            {
              key: 'value'
            }
          ]
        });

        let filter = {
          name: 'verifyIMG',
          fn: function (item, options) {
            return item.type.indexOf("image/") !== -1;
          }
        };

        $scope.uploader.filters.push(filter);

        $scope.uploader.onAfterAddingFile = (item) => {
          let partes = item.file.name.split(".");
          $scope.newFileName = $scope.uuid + "." + partes[partes.length - 1];

          if ($scope.uploader.queue.length > 1)
            $scope.uploader.queue.splice(0, $scope.uploader.queue.splice.length - 1);
        };

        $scope.uploader.onWhenAddingFileFailed = (img) => {
          self.UI.addToast("Por favor, carregue uma imagem");
        };

        $scope.uploader.onErrorItem = (response, status, headers) => {
          self.UI.addToast("A imagem não foi carregada com sucesso");
        };

        $scope.uploader.onBeforeUploadItem = (item) => {
          item.file.name = $scope.newFileName;
        };


        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate.then((ok) => {

      this.App.create({
        id: 0,
        nome: ok.app.nome,
        descricao: ok.app.descricao,
        package: ok.app.package,
        urlDev: ok.app.urlDev,
        urlProd: ok.app.urlProd,
        urlStaging: ok.app.urlStaging,
        estado: 1,
        clientId: ok.clientOption.id,
        plataforma: ok.plataformaOption.option,
        logotipo: ok.newFileName,
        active: 1
      }).$promise.then((res) => {


        console.log("RES", res.clientId);

        if (ok.newFileName) {
          // File exists, upload it
          ok.uploader.uploadAll();
          this.getAppActive();
          this.getAppInactive();
        } else {
          // No file, just update the list
          this.getAppActive();
          this.getAppInactive();
        }
        this.UI.addToast("Aplicação adicionada com sucesso!");
      }).catch(err => {
        this.UI.addToast("Erro na criação de aplicação!");
        console.log(err);
      });
    }).catch((error) => {
      if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
        console.log(error);
    });
  };


  editApp = (appEdit) => {
    let self = this;
    let options = {
      size: 'md',
      template: require('./modal-editar.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        console.log("this.clients: ",self.clients);
        console.log("appEdit.AppClient", appEdit.AppClient);
        $scope.app = {};
        $scope.app.id = appEdit.id;
        $scope.app.nome = appEdit.nome;
        $scope.app.descricao = appEdit.descricao;
        $scope.app.package = appEdit.package;
        $scope.app.logotipo = appEdit.logotipo;
        $scope.app.urlDev = appEdit.urlDev;
        $scope.app.urlProd = appEdit.urlProd;
        $scope.app.urlStaging = appEdit.urlStaging;
        $scope.plataformaOptions = [{option: 'Android'}, {option: 'iOS'}, {option: 'Flutter'}, {option: 'Web'}];
        $scope.plataformaOption = {option: appEdit.plataforma};
        $scope.estadoOptions = [{option: 'Ativo'}, {option: 'Inativo'}];
        $scope.estadoOption = {option: appEdit.estado ? 'Ativo' : 'Inativo'};
        $scope.clientOptions = self.clients;
        $scope.clientOption = appEdit.AppClient;

        $scope.label = "Editar aplicação";
        $scope.newFileName = '';

        $scope.uuid = self.generateUUID();

        $scope.uploader = new self.FileUploader({
          url: '/api/Upload/files/upload',
          queueLimit: 1,
          formData: [
            {
              key: 'value'
            }
          ]
        });

        let filter = {
          name: 'verifyIMG',
          fn: function (item, options) {
            return item.type.indexOf("image/") !== -1;
          }
        };

        $scope.uploader.filters.push(filter);

        $scope.uploader.onAfterAddingFile = (item) => {
          let partes = item.file.name.split(".");
          $scope.newFileName = $scope.uuid + "." + partes[partes.length - 1];

          if ($scope.uploader.queue.length > 1)
            $scope.uploader.queue.splice(0, $scope.uploader.queue.splice.length - 1);
        };

        $scope.uploader.onWhenAddingFileFailed = (img) => {
          self.UI.addToast("Por favor, carregue uma imagem");
        };

        $scope.uploader.onErrorItem = (response, status, headers) => {
          self.UI.addToast("A imagem não foi carregada com sucesso");
        };

        $scope.uploader.onBeforeUploadItem = (item) => {
          item.file.name = $scope.newFileName;
        };


        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogCreate = this.UI.showDialog(options);


    dialogCreate.then((a) => {

      this.App.findOne({
        filter: {
          where: {
            id: a.app.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.nome = a.app.nome;
        res.descricao = a.app.descricao;
        res.package = a.app.package;
        res.urlDev = a.app.urlDev;
        res.urlProd = a.app.urlProd;
        res.clientId = a.clientOption.id;
        res.urlStaging = a.app.urlStaging;
        res.plataforma = a.plataformaOption.option;
        res.estado = a.estadoOption.option;
        res.logotipo = a.app.logotipo;
        if (a.estadoOption.option === 'Ativo') {
          res.estado = 1;
        } else {
          res.estado = 0;
        }
        res.$save().then((r) => {

          if (a.newFileName)
            this.insertNewAnexo(a);
          else
            this.getAppActive();
          this.getAppInactive();
          this.UI.addToast("Aplicação editada com sucesso!");
        }).catch(err => {
          this.UI.addToast("Erro na edição de aplicação!");
          console.log(err);
        });
      }).catch((error) => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
          console.log(error);
      });
    });
  };

  removeApp = (a) => {
    this.App.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((res) => {
      let options = {
        size: 'md',
        template: require('./modal-remover.view.html'),
        controller: ['$scope', '$dialog', ($scope, $dialog) => {

          $scope.ok = () => {
            $dialog.close(res);
          };
          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      };

      let dialogRemove = this.UI.showDialog(options);

      dialogRemove.then((result) => {
        result.active = 0;
        result.$save().then((r) => {
          this.UI.addToast("Aplicação removida com sucesso!");
          this.getAppActive();
          this.getAppInactive();
        }).catch((error) => {
            console.log(error);
          }
        ).catch((error) => {
          console.log(error);
        })
      }).catch((err) => {
        console.log(err);
      });
    })
  };

  getAppActive = () => {
    this.appLoading = true;
    this.App.find({
      filter: {
        where: {
          active: 1,
          estado: 1
        }, include: 'AppClient'
      }
    }).$promise.then((res) => {
      this.appsAt = res;

      this.table = new this.NgTableParams({
        count: 50,
        sorting: {
          nome: 'asc'
        }
      }, {
        dataset: this.appsAt
      });

      this.appLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  getAppInactive = () => {
    this.appLoading = true;
    this.App.find({
      filter: {
        where: {
          active: 1,
          estado: 0
        },
        include: 'AppClient'
      }
    }).$promise.then((res) => {
      this.appsIn = res;

      this.tableInativo = new this.NgTableParams({
        count: 50,
        sorting: {
          nome: 'asc'
        }
      }, {
        dataset: this.appsIn
      });

      this.appLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };
}

AppController.$inject = ['UIService', 'App', 'Client', 'NgTableParams', '$rootScope', '$state', '$filter', 'FileUploader', '$http'];
