export default class SupportListController {
  constructor($state, Support, Client, UIService) {
    this.Support = Support;
    this.Client = Client;
    this.UI = UIService;
    this.$state = $state;
    this.loaded = false;
    this.client = null;
    this.loadData(this.$state.params.client);
  }

  sync = () => {
    let syncing = this.UI.showWaiting();
    this.Support.zammad().$promise.then(r => {
      syncing.close();
      this.UI.addToast("Sincronização efetuada com sucesso");
      this.loadData(this.$state.params.client);
    }).catch(() => {
      syncing.close();
      this.UI.addToast("Sincronização falhou!");
      this.loadData(this.$state.params.client);
    });
  }

  url = () => {
    this.$state.go('app.support.list', {
      client: this.client.id
    }, {
      notify: false,
      reload: false,
      location: 'replace',
      inherit: true
    });
    this.loadData(this.client.id);
  };

  loadData = (arg) => {
    this.loaded = false;
    this.Client.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(res => {
      if (arg && this.client === null) {
        this.client = res.find(r => r.id === arg);
      }
      this.clients = res;
    }).catch(() => {
      this.UI.addToast('Erro ao carregar clientes');
    });

    if (arg) {
      this.Client.findOne({
        filter: {
          where: {
            id: arg
          },
          include: {
            relation: 'contracts',
            scope: {
              include: {
                relation: 'supports',
                scope: {
                  order: 'date DESC',
                  include: [{
                    relation: 'contact',
                    scope: {
                      include: 'client',
                    }
                  }]
                }
              }
            }
          }
        }
      }).$promise.then(res => {
        this.list = [];
        res.contracts.forEach(r => {
          this.list.push(...r.supports);
        });
        // Filter by this week
        this.thisWeek = [];
        // Older than 1 week
        this.older = [];
        this.list.forEach(res => {
          if (moment(res.date).isSame(new Date(), 'week')) {
            this.thisWeek.push(res);
          } else {
            this.older.push(res);
          }
        });
        this.loaded = true;
      }).catch((a) => {
        console.log(a);
        this.UI.addToast('Erro ao carregar tickets');
      });
    } else {
      this.Support.find({
        filter: {
          order: 'date DESC',
          include: [{
            relation: 'contact',
            scope: {
              include: 'client'
            }
          }]
        }
      }).$promise.then((res) => {
        this.list = res;
        // Filter by this week
        this.thisWeek = [];
        // Older than 1 week
        this.older = [];
        this.list.forEach(res => {
          if (moment(res.date).isSame(new Date(), 'week')) {
            this.thisWeek.push(res);
          } else {
            this.older.push(res);
          }
        });
        this.loaded = true;
      }).catch(() => {
        this.UI.addToast('Erro ao carregar tickets');
      });
    }
  };
}

SupportListController.$inject = ['$state', 'Support', 'Client', 'UIService'];
