export default class SupportAddController {
  constructor($state, $rootScope, Contact, Contract, Client, UIService, Support, AuthenticationService, FileUploader) {
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.Contact = Contact;
    this.Contract = Contract;
    this.Client = Client;
    this.UI = UIService;
    this.Support = Support;
    this.loadData();
    this.ticket = {
      origin: {},
      attachments: [],
      description: "",
      urgent: false,
      active: true,
      observations: "",
      userId: AuthenticationService.getId(),
      contract: null
    };
    // Setup file uploader
    this.uploader = new FileUploader({
      url: '/api/assets/upload/attachments'
    });

    this.  uploader.filters.push({
      name: 'imageFilter',
      fn: function(item /*{File|FileLikeObject}*/, options) {
        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|jpg|png|jpeg|bmp|gif|webp|doc|docx|html|txt|odt||xls|xlsx|csv|ods|pdf|zip|rar|gz|'.indexOf(type) !== -1;
      }
    });

    this.uploader.onSuccessItem = (file, response, status, headers) => {
      if (response.result) {
        this.ticket.attachments.push({
          container: response.result.container,
          year: response.result.year,
          month: response.result.month,
          name: response.result.name,
          size: response.result.size,
        });
      }
    };

    this.uploader.onCompleteAll = () => {
      this.waiting.close();
      this.ticket.attachments.push(...this.$rootScope.taImages);
      this.Support.create({
        userId: this.ticket.userId,
        urgent: this.ticket.urgent || false,
        elapsed: 0,
        request: this.ticket.request,
        origin: this.ticket.origin,
        contactId: this.ticket.contactId,
        contractId: this.ticket.contract.id,
        summary: this.ticket.summary,
        description: this.ticket.description || "",
        date: new Date(),
        attachments: this.ticket.attachments
      }).$promise.then(r => {
        this.UI.addToast("Ticket adicionado com sucesso");
        this.$state.go('app.support.details', {id: r.id});
      }).catch(() => {
        this.UI.addToast("Ocorreu um erro ao adicionar ticket");
      });
    }
  };

  getFileExtension = a => {
    if (a && a.name) {
      let images = "|jpg|png|jpeg|bmp|gif|webp|";
      let documents = "|doc|docx|txt|odt|html|";
      let sheets = "|xls|xlsx|csv|ods|";
      let zips = "|zip|rar|gz|";
      let extension = a.name.split(".").pop();
      if (extension === "pdf") {
        return "file-pdf";
      } else if (images.indexOf(extension) !== -1) {
        return "file-image"
      } else if (documents.indexOf(extension) !== -1) {
        return "file-document";
      } else if (sheets.indexOf(extension) !== -1) {
        return "file-excel";
      } else if (zips.indexOf(extension) !== -1) {
        return "zip-box";
      } else {
        return "file";
      }
    }
  };

  loadContract = (clientId) => {
    let waiting = this.UI.showWaiting();
    this.Client.findById({
      id: clientId,
      filter: {
        include: 'contracts'
      }
    }).$promise.then(res => {
        this.contracts = res.contracts;
        if (res.length === 1) {
          this.ticket.contract = res[0];
        }
        waiting.close();
      }
    );
  };

  loadData = () => {
    this.loaded = false;
    this.Client.find().$promise.then(clients => {
      this.clients = clients;
      this.Contact.find({
        filter: {
          include: 'client'
        }
      }).$promise.then(res => {
        this.contacts = res;
        this.loaded = true;
        if (this.ticket.user == null) {
          this.userPicker();
        }
      });
    });

  };

  userPicker = () => {
    let dialog = this.UI.showDialog({
      template: require('./pick.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.op = {
          selected: {}
        };
        //Infinite Scroll Magic
        $scope.infiniteScroll = {};
        $scope.infiniteScroll.numToAdd = 20;
        $scope.infiniteScroll.currentItems = 20;
        $scope.contacts = this.contacts;

        $scope.addMoreItems = function () {
          $scope.infiniteScroll.currentItems += $scope.infiniteScroll.numToAdd;
        };

        $scope.ok = () => {
          $scope.$close($scope.op.selected);
        };

        $scope.cancel = () => {
          $scope.$dismiss();
        };
      }]
    });

    dialog.then((res) => {
      this.contact = res;
      this.ticket.contactId = res.id;
      if (res.data.length > 0) {
        this.ticket.origin = res.data[0];
      }
      this.loadContract(res.clientId);
    }).catch(() => {
      this.$state.go('app.support.list');
    });
  };

  validate = () => {
    // Validate data
    if (!this.ticket.hasOwnProperty('contactId')) {
      return false;
    }
    if (!this.ticket.origin.hasOwnProperty('label')) {
      return false;
    }
    if (!this.ticket.contract.hasOwnProperty('type')) {
      return false;
    }
    if (!this.ticket.hasOwnProperty('summary')) {
      return false;
    }
    if (!moment(this.ticket.request).isValid()) {
      return false;
    }
    return true;
  };

  submit = () => {
    if (!this.validate()) {
      this.UI.addToast("Existem erros no preenchimento do formulário!");
    } else {
      if (this.uploader.queue.length > 0) {
        this.waiting = this.UI.showWaiting();
        this.uploader.uploadAll();
      } else {
        this.ticket.attachments.push(...this.$rootScope.taImages);
        let data = {
          userId: this.ticket.userId,
          urgent: this.ticket.urgent || false,
          elapsed: 0,
          request: this.ticket.request,
          origin: this.ticket.origin,
          contactId: this.ticket.contactId,
          contractId: this.ticket.contract.id,
          summary: this.ticket.summary,
          description: this.ticket.description || "",
          date: new Date(),
          attachments: this.ticket.attachments
        };
        this.Support.create(data).$promise.then(r => {
          this.UI.addToast("Ticket adicionado com sucesso");
          this.$state.go('app.support.details', {id: r.id});
        }).catch(() => {
          this.UI.addToast("Ocorreu um erro ao adicionar ticket");
        });
      }
    }
  }
}

SupportAddController.$inject = ['$state', '$rootScope', 'Contact', 'Contract', 'Client', 'UIService', 'Support', 'AuthenticationService', 'FileUploader'];
