export default class AuthorizationService {
  constructor($rootScope, $q, Rolemap, AuthenticationService) {
    this.$rootScope = $rootScope;
    this.Rolemap = Rolemap;
    this.AuthenticationService = AuthenticationService;
    this.$q = $q;
    this.roles = [];
    this.groups = [];

    $rootScope.$on('$stateChangeStart', () => {
      if (AuthenticationService.isAuthenticated())
        this.loadACLs();
    });

    if (AuthenticationService.isAuthenticated())
      this.loadACLs();
  }

  getAllowed = () => {
    return this.roles;
  };

  // Obtem lista de todas as funcionalidades presentes nas roles associadas ao user...
  loadACLs = () => {
    const user = this.AuthenticationService.getUser();
    if (_.isEmpty(user))
      return;
    let k = [];
    let j = [];
    user.groups.forEach(g => {
      j.push(g);
      g.rolegroup.forEach(r => {
        if (r.active === true)
        k.push(r.role.name);
      });
    });
    // Important, otherwise we will have an exception in canPerform...
    k.push('$authenticated');
    this.roles = _.uniqBy(k);
    this.groups = _.uniqWith(j, _.isEqual);
  };

  isAuthenticated = () => {
    return this.AuthenticationService.isAuthenticated() && this.roles.length > 0;
  };

  // Verifica se o utilizador pode executar conjunto de roles...
  canPerform = (roles) => {
    return _.includes(this.roles, roles);
  };

  // Verifica se o utilizador pertence a um grupo
  belongsTo = (group) => {
    return _.some(this.groups, g => {
      return (_.isEqual(g,group) || g.name === group || g.id === group);
    });
  };
}

AuthorizationService.$inject = ['$rootScope', '$q', 'Rolemap', 'AuthenticationService'];
