export default class ClientListController {
  constructor(Client, NgTableParams, UIService) {
    // Declarations
   this.Client = Client;
   this.Table = NgTableParams;
   this.UI = UIService;
   // Load data
   this.loadData();
  }

  loadData = () => {
    this.Client.find({
      filter: {
        include: 'contacts'
      }
    }).$promise.then(res => {
      this.clients = new this.Table({
        page: 1,
        count: 25,
        sorting: {'id': 'desc'}
      }, {
        dataset: res
      });
      this.data = res;
      this.loaded = true;
    });
  };

  add = () => {
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./client.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.client ={
          active: true
        };

        $scope.generateCode = function() {
          if ($scope.client.hasOwnProperty('name')) {
            let matches = $scope.client.name.match(/\b(\w)/g); // ['J','S','O','N']
            $scope.client.code = matches.join(''); // JSON
          }
        };

        $scope.ok = () => {
          $scope.$close($scope.client);
        };

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    });

    dialog.then(res => {
      if (res) {
        this.Client.create(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast("Cliente adicionado!");
        }).catch(() => {
          this.UI.addToast("Ocorreu um erro ao adicionar cliente");
        })
      }
    });
  }
}

ClientListController.$inject = ['Client', 'NgTableParams', 'UIService'];
