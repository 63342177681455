export function routes($stateProvider) {

  $stateProvider
    .state('app.support', {
      url: '/support',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.support.add', {
      url: '/add',
      template: require('./new/new.view.html'),
      controller: 'SupportAddController',
      controllerAs: 'vm'
    })
    .state('app.support.list', {
      url: '?client',
      template: require('./list/list.view.html'),
      controller: 'SupportListController',
      controllerAs: 'vm',
      params: {
        client: {
          dynamic: true
        }
      }
    })
    .state('app.support.details', {
      url: '/{id}',
      template: require('./details/details.view.html'),
      controller: 'SupportDetailsController',
      controllerAs: 'vm'
    })
}

routes.$inject = ['$stateProvider'];
