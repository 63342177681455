export function routes($stateProvider) {

  $stateProvider
    .state('public.package', {
      url: '/package/:name/:env?version',
      params: {
        version: {
          dynamic: true
        },
        env: 'prod'
      },
      template: require('./package/package.view.html'),
      controller: 'PackageController',
      controllerAs: 'vm'
    })
}

routes.$inject = ['$stateProvider'];
