import angular from 'angular';
import {routes} from './routes'
import ClientListController from "./list/list.controller";
import ClientDetailsController from "./details/details.controller";


export default angular.module('app.clients', [])
  .config(routes)
  .controller('ClientListController', ClientListController)
  .controller('ClientDetailsController', ClientDetailsController)
  .name;
