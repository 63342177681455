export function routes($stateProvider) {
  $stateProvider
    .state('app', {
      template: require('./layout/layout.view.html'),
      controller: 'LayoutController',
      controllerAs: 'vm'
    })
    .state('public', {
      url: '/public',
      abstract: true,
      template: require('./layout/public.view.html'),
    })
    .state('auth', {
      template: '<div class="loginBg" ui-view></div>'
    })
    .state('app.dashboard', {
      template: require('./dashboard/dashboard.view.html'),
      url: '/?page&count&sort&filter&group',
      title: 'Dashboard',
      controller: 'DashboardController',
      controllerAs: 'vm',
    //  role: '$authenticated'
    })
    .state('app.profile', {
      template: require('./profile/profile.view.html'),
      url: '/profile',
      title: 'Perfil',
      controller: 'ProfileController',
      controllerAs: 'vm',
      role: '$authenticated'
    })
    .state('app.changelog', {
      url: '/changelog',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.changelog.view', {
      template: require('./changelog/changelog.view.html'),
      url: '/',
      params: {
        major: undefined,
        minor: undefined,
        patch: undefined,
        expanded: false,
      },
      title: 'Changelog',
      controller: 'ChangelogController',
      controllerAs: 'vm',
       role: '$authenticated'
    })
    .state('app.changelog.edit', {
      template: require('./changelog/change/change.view.html'),
      url: '/editor/:major/:minor/:patch',
      controller: 'ChangelogEditorController',
      controllerAs: 'vm',
      role: 'changelog'
    })
    .state('app.changelog.add', {
    template: require('./changelog/change/addChange.view.html'),
    url: '/add',
    controller: 'ChangelogEditorController',
    controllerAs: 'vm',
    role: 'changelog'
  });
}

routes.$inject = ['$stateProvider'];
